<template>
  <div>
    <div v-if="result.valueLine.commandType != 'multiCommand'">
      <defaultFieldTypes
        :field="field"
        :fieldAttributes="{ ...fieldAttributes }"
        v-on="$listeners"
        :templateContent="result"
      :value="value"></defaultFieldTypes>
    </div>
  </div>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  components: { defaultFieldTypes },
  created() {
    this.updateColAttrs(this.result.valueLine.commandType);
  },
  props: ["result", "fieldAttributes", "field","value"],
  watch: {
    "result.valueLine.commandType": function (newValue) {
      this.updateColAttrs(newValue);
    },
  },
  methods: {
    updateColAttrs(newValue) {
      if (newValue != "multiCommand") {
        this.$delete(this.fieldAttributes.colAttrs, "style");
      } else {
        this.$set(this.fieldAttributes.colAttrs, "style", "max-width:0px");
        this.$set(this.fieldAttributes.colAttrs, "class", "mr-0");
      }
    },
  },
};
</script>